import * as React from 'react'


import './css/HdaList.css'
import './modals/css/Popup.css'
import HdaSection from './HdaSection'

import VoteGridModal from './modals/VoteGridModal'
import HistoryModal from './modals/HistoryModal'
import InfoModal from './modals/InfoModal'


import WpApi from './wpapi'
import Loader from './Loader'



interface MyProps {
    post_id: string,
    user:any,
    view:string
}



type MyState = {
    section_rows: Array<any>,
    msg?: string,
    loading:boolean,
    need_recalc:boolean
};

interface cb_after_vote_event {
    (r:any): void;
}



class HdBlockList extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";

    private vote_popup =  React.createRef<VoteGridModal>();
    private history_popup =  React.createRef<HistoryModal>();
    private info_popup =  React.createRef<InfoModal>();


    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}
    private fetchPromise ?: Promise<void>
    
    constructor(props: MyProps) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)

	
	this.state = {
	    section_rows: [],
	    loading:false,
	    need_recalc:false,
	    msg:undefined
	}
    }

    public handleClick(e:any){
	this.init()
    }
    scroll_position(){
	console.log("scroll-position")
	let p = new URLSearchParams(window.location.search)
	let key = p.get('acfkey')
	console.log(`scroll to ${key}`)
	let q = `div[acf-key="${key}"]`
	console.log(q)
	let elm = document.querySelector(q)
	console.log(elm)
	if(elm instanceof HTMLElement){
	    elm.classList.add("highlight")
	    window.scroll({
		top: elm.offsetTop,
		left: 0,
		behavior: "smooth",
	    });

	}
    }
    public init(){
	this.setState({loading:true})
	if(this.props.post_id === "-"){
	    let r :Array<any> = []
	    //r = get_dummy_data()
	    console.log(r)
	    this.setState({ section_rows : r,
			    loading:false
			  })
	}else{
	    
	    if (this.fetchPromise) {
		console.log(' already mounted previously')
		return;
	    }

	    let d = {
		post_id:this.props.post_id
	    }
	    this.fetchPromise = WpApi.do_get('/hd-actions', d,(r) => {
		if(r.code === "rest_forbidden"){
		    this.setState({msg: 'No access. '+r.message})
		}else{
		    setTimeout(()=>{
			this.scroll_position()},500)
		    this.setState({
			section_rows  : r.sections,
			need_recalc: r.need_recalc,
			loading:false
		    })
		}
	    })
	}
    }

    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    // signal is received from Section
    public open_popup(e:any, cb:(r:any)=>void){
	console.log(`open-popup cmd: ${e.cmd}`)
	if(e.cmd === 'choice-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'advise-comment-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'inputs-comment-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'advise-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'comment-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'number-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'open-history'){
	    this.history_popup.current!.init(e)
	}else if(e.cmd === 'open-info'){
	    this.info_popup.current!.init(e.txt)
	}
	// point callback to the calling Section
	this.callback_after_vote_event = cb;
    }

    /*
     *  user clicked in VoteGrid, send post to api
     */
    public after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)

	let d :{[name:string]:string}= {
	    post_id: this.props.post_id
	}
	if(v.tup !== undefined){
	    if(v.tup.select !== undefined){
		d.acf_key = v.tup.select.key
	    }
	}
	if(v.acf_key !== undefined){
	    d.acf_key = v.acf_key
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.aspect !== undefined){
	    d.aspect = v.aspect
	}
	if(v.bouwdeel !==undefined){
	    d.bouwdeel = v.bouwdeel
	}
	if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }

	}else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	    d.votetype = 'remove_vote'
	    d.is_final_field = v.is_final_field	    
	}else if(v.kind === 'add_comment'){
	    d.votetype = 'add_comment'
	    d.value = v.txt
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}else if(v.kind === 'add_advise'){
	    d.votetype = 'add_advise'
	    d.value = v.txt
	}else if(v.kind === 'update_advise'){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.value
	}else if(v.kind === 'update_comment'){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}else if(v.kind === 'add_number'){
	    d.votetype = 'add_number'
	    d.value = v.val
	}else{
	    console.log("unknown v.kind")
	    return
	}
	console.log(d)
	this.callback_after_vote_event({ hook: 'before_api_call'})

	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
	
    }
    show_step(tup:any){
	if(this.props.view === "buildingmeasures"){
	    return false
	}else{
	    if(tup.cftype === "hda-bouwdelen-stap"){
		return false
	    }
	}
	return true
    }

    show_row(row:any){
	if(this.props.view === "buildingmeasures"){
	    return row.class === 'buildingpart'
	}
	else if(this.props.view === "actionlist"){
	    return row.class === 'buildingpart' ||  row.class === 'actions' || row.cftype === "header"
	}
	else if(this.props.view === "collab_input"){
	    return row.class === 'input'
	}
	else if(this.props.view === "combi"){
	    return row.class === 'buildingpart'
	}
	else if(this.props.view === "quotations"){
	    return row.class === 'quotations'
	}
	return true
    }
    jump_to(row:any){
	console.log('jump-to')
	console.log(row)
	let key = row.key
	console.log(`scroll to ${key}`)
	let q = `div[acf-key="${key}"]`
	console.log(q)
	let elm = document.querySelector(q)
	console.log(elm)
	if(elm instanceof HTMLElement){
	    elm.classList.add("highlight")
	    window.scroll({
		top: elm.offsetTop,
		left: 0,
		behavior: "smooth",
	    });
	}
    }
    render_toc(){
	return this.state.section_rows.map((row, i) => {
	    var label = row.label
	    if(this.props.view === "actionlist"){
		if(row.cft_identifier){
		    label = row.cft_identifier + '. ' + row.label
		}
	    }
	    let cls = row.cftype === 'header' ? 'header-main' : 'header-default'
	    return this.show_row(row) ? (
		<div key={i} className={cls}>
		    <div  className="item level-1" onClick={()=>this.jump_to(row)}>{label}</div>
		</div>
	    ) : null
	})
    }
    render_sections(){
	return this.state.section_rows.map((row, i) => {
	    return this.show_row(row) ? this.render_row(row,i) : null
	})
    }
    render_row(row:any, i:number){
	return row.cftype === "header" ? (
	    <div acf-key={row.key} className="main-header" key={i}>{row.label}</div>
	)  : (
		<HdaSection
		    user={this.props.user}
		    view={this.props.view}
		    cb_parent={this.open_popup}
		    key={i} post_id={this.props.post_id} tup={row} />
	)
    }

    public render(){
			//Calculations status:   {this.state.need_recalc ? 'recalculation needed' : 'up-to-date'  }
	return (
	    <div className="hd-actions-react">
		{this.state.loading ? <Loader /> : null}
		<div className="msg">{this.state.msg} </div>
		<div className="toc">
		    <h3>Inhoudsopgave</h3>
		    {this.render_toc()}
		</div>
		<div className="sections">
		    {this.render_sections()}
		</div>
		<VoteGridModal ref={this.vote_popup} cb_parent={this.after_vote_event} post_id={this.props.post_id} />
		<HistoryModal ref={this.history_popup}  post_id={this.props.post_id} />
		<InfoModal ref={this.info_popup} />
	    </div>
	);
    }
}
export default HdBlockList;
