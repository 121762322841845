import * as React from 'react'


import './css/HdaList.css'
import './css/Quotations.css'
import QuotationSection from './QuotationSection'
import VoteGridModal from './modals/VoteGridModal'
import HistoryModal from './modals/HistoryModal'
import InfoModal from './modals/InfoModal'

import WpApi from './wpapi'
import Loader from './Loader'



interface MyProps {
    post_id: string,
    is_simple_block:boolean,
    readonly_mode:boolean,
    user:any,
    supplier?:string,
    acfkey?:string,
    view:string
}



type MyState = {
    section_rows: Array<any>,
    options:any,
    headers:any,
    actiestappen:any,
    msg?: string,
    loading:boolean,
    need_recalc:boolean
};


interface cb_after_vote_event {
    (r:any): void;
}



class Quotations extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";
    private fetchPromise ?: Promise<void>
	private vote_popup =  React.createRef<VoteGridModal>();
    private history_popup =  React.createRef<HistoryModal>();
    private info_popup =  React.createRef<InfoModal>();
    
    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}
	
    constructor(props: MyProps) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)	
	this.state = {
	    section_rows: [],
	    loading:false,
	    need_recalc:false,
	    options:null,
	    headers:null,
	    actiestappen:null,
	    msg:undefined
	}
    }
    public open_popup(e:any, cb:(r:any)=>void){
	console.log(`open-popup cmd: ${e.cmd}`)
	console.log(e)
	let votetypes = [
	    'quotations-comment-vote',
	    'quotation-status-vote',
	]
	if(votetypes.includes(e.cmd)){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'quotation-history'){
	    this.history_popup.current!.init(e)
	}else{
	    console.log("ERROR unknown cmd")
	    return
	}
	// point callback to the calling Section
	this.callback_after_vote_event = cb;
    }
    public after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)
	let d :{[name:string]:string}= {
	    post_id: this.props.post_id,
	    votetype: v.kind,
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.kind == "add_comment"){
	    d.on_attr_id =  v.on_attr_id
	    d.aspect = v.aspect
	    d.value = v.txt
	}
	else if(v.kind == "update_comment"){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	}
	else if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    d.aspect = v.aspect
	    d.acf_key = v.acf_key_select
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else{
	    console.log('error unknown kind')
	    return
	    
	}
	console.log(d)
	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
    }
    public handleClick(e:any){
	this.init()
    }
    scroll_position(){
	console.log("scroll-position")
	let p = new URLSearchParams(window.location.search)
	let key = p.get('acfkey')
	console.log(`scroll to ${key}`)
	let q = `div[acf-key="${key}"]`
	console.log(q)
	let elm = document.querySelector(q)
	console.log(elm)
	if(elm instanceof HTMLElement){
	    elm.classList.add("highlight")
	    window.scroll({
		top: elm.offsetTop,
		left: 0,
		behavior: "smooth",
	    });

	}
    }
    public init(){
	this.setState({loading:true})
	if(this.props.post_id === "-"){
	    let r :Array<any> = []
	    //r = get_dummy_data()
	    console.log(r)
	    this.setState({ section_rows : r,
			    loading:false
			  })
	}else{
	    if (this.fetchPromise) {
		console.log(' already mounted previously')
		return;
	    }

	    let d = {
		post_id:this.props.post_id
	    }
	    this.fetchPromise = WpApi.do_get('/hd-quotations', d,(r) => {
		if(r.code === "rest_forbidden"){
		    this.setState({msg: 'No access. '+r.message})
		}else{
		    this.setState({
			section_rows  : r.sections,
			options : r.options,
			headers: r.headers,
			actiestappen: r.actiestappen,
			loading:false
		    })
		}
	    })
	    
	}
    }

    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    
    render_cats(){
	let postid = Number(this.props.post_id);
	return  this.state.section_rows.map((row, i) => {
	    if(this.props.acfkey && row.key != this.props.acfkey){
		return null
	    }
	    let simple_block = (this.props.acfkey !== null)
	    if(row.maatregel !== undefined){
		return (
		    <QuotationSection
			key={i}
			is_simple_block={this.props.is_simple_block}
			readonly_mode={this.props.readonly_mode}
			cb_parent={this.open_popup}
			simple_options={this.state.options.simple_options}
			status_options={this.state.options.status_options}
			status_buttons={this.state.options.status_buttons}
			bouwdeel_options={this.state.options.bouwdelen}
			headers={this.state.headers}
			actiestappen={this.state.actiestappen}
			supplier={this.props.supplier}
			tup={row}
			postid={postid}
		    />
		)
	    }
	    
	})
    }

    public render(){

	return (
	    <div className="hd-actions-react">
		{this.state.loading ? <Loader /> : null}
		<div className="msg">{this.state.msg} </div>
		<div className="sections">
		{this.render_cats()}
	    </div>
		<VoteGridModal ref={this.vote_popup} cb_parent={this.after_vote_event} post_id={this.props.post_id} />
		<HistoryModal ref={this.history_popup}  post_id={this.props.post_id} />

	    </div>
	);
    }
}
export default Quotations
