import * as React from 'react';
import ValueWithComments from "./ValueWithComments"
import Loco from "./Loco"
import ChoiceInputWithAdvise from "./ChoiceInputWithAdvise"
import NumberInputWithAdvise from "./NumberInputWithAdvise"
import CollabOutput from "./CollabOutput"
import V1Inputs from "./V1Inputs"
import CalculatedOutput from "./CalculatedOutput"

interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    permission:any,
    tup:any
}
type MyState = {
}
interface cb_after_vote {
    (r:any): void;
}

class SubSection extends React.PureComponent<MyProps,MyState> {
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}
    private output =  React.createRef<CollabOutput>()
    private calculated_output =  React.createRef<CalculatedOutput>()
    
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	
    }
    public handle_cb(e:any, cb:(r:any)=>void){
	this.callback_after_vote = cb;
	if(e.cmd === "set-section-status"){
	    console.log(e)
	    this.setState({ completed : (e.val === "ready")})
	}else{
	    this.props.cb_parent(e,(r:any)=>{
		console.log("result after vote "+e.cmd)
		console.log(r)
		if(e.cmd == "inputs-comment-vote"){
		    console.log('comment-vote')
		}else{
		    if(r.winner_txt != null){
			if(this.props.tup.class.includes("with-output-formula")){
			    if(this.calculated_output.current != null){
				this.calculated_output.current.set_winner(r.winner_txt)
			    }
			}else{
			    if(this.output.current != null){
				this.output.current.set_winner(r.winner_txt)
			    }
			}
		    }
		}
		// reverse signal to Step
		this.setState({ completed : (r.section_status === "ready")})
		this.callback_after_vote(r)
	    })
	}
    }
    render(){
	let stup = this.props.tup
	
	return (
	    <div className="sub-section" acf-key={stup.key}>
		<div className="header">
		  <div className="label"> {stup.label}</div>
		</div>
		<div className="steps">
		{stup.subfields.map((tup:any, j:number) => {
		    if(tup.class === "v1-read-inputs"){
			return (<V1Inputs
				tup={tup} key={j}
				parent_label={stup.label}
				    cb_parent={this.handle_cb}/>
			    )
		    }else if(tup.class === "calculation"){
			return (<CalculatedOutput tup={tup} key={j}
				ref={this.calculated_output}				
				cb_parent={this.handle_cb}
			    />)
		    }else if(tup.cftype === "collab-number-input"){
			return (<NumberInputWithAdvise
				tup={tup} key={j}
				stup={stup}
				permission={this.props.permission}
				cb_parent={this.handle_cb}
			    />)
		    }else if(tup.cftype === "collab-choice-input"){
			    return (<ChoiceInputWithAdvise
				    permission={this.props.permission}
				    tup={tup} key={j}
				    stup={stup}
				    cb_parent={this.handle_cb}
				/>)
		    }else if(tup.cftype === "collab-output"){
			return (<CollabOutput tup={tup} key={j}
				ref={this.output}
				cb_parent={this.handle_cb}
			    />)
		    }
		})}		
		</div>
	    </div>
	)
    }

}

export default SubSection
