import * as React from 'react';
import CollabChoiceInput from "./CollabChoiceInput"
import Loco from "./Loco"

interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    permission:any,
    stup:any,
    tup:any
}
type MyState = {
}

class CollabChoiceInputWithAdvise extends React.PureComponent<MyProps,MyState> {

    render(){
	let tup = this.props.tup

	let label_1 = Loco.tr('according_to_owners') 
	let label_2 = Loco.tr('according_to_advisers')
	

	return (
	    <div className="input-with-advise section-part">
		<CollabChoiceInput
		    aspect="huidig"
		    permission={this.props.permission.huidig}
		    label={label_1}
		    parent_label={this.props.stup.label}
		    tup={tup.huidig}
		    select={tup.select}
		    cb_parent={this.props.cb_parent}
		/>
		<CollabChoiceInput
		    aspect="voorstel"
		    permission={this.props.permission.voorstel}
		    label={label_2}
		    parent_label={this.props.stup.label}
		    tup={tup.voorstel}
		    select={tup.select}
		    cb_parent={this.props.cb_parent}
		/>
	    </div>
	)

    }
}

export default CollabChoiceInputWithAdvise
