import * as React from 'react';


interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    tup:any
}
type MyState = {
    is_loading:boolean,
    comments: Array<string>,
    value:string
}

class CalculatedOutput extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)
	this.state = {
	    comments: [],
	    value:'',
	    is_loading:false
	}
    }
    public set_winner(v:string){
	console.log(`set winner ${v}`)
	this.setState({value:v})
    }
    componentDidMount(){
	if(this.props.tup.nice_value){
	    this.setState({value: this.props.tup.nice_value})
	}
	else if(this.props.tup.value){
	    this.setState({value: this.props.tup.value})
	}

	if(this.props.tup.comment){
	    let c = this.props.tup.comment
	    this.setState({comments:c.map((x:any)=>x.value)})
	}
    }

    handle_comment_click(){
	console.log('comments-clicked')
	this.props.cb_parent({
	    cmd: 'inputs-comment-vote',
	    acfkey: this.props.tup.key,
	    aspect:'huidig',
	    label: ' label X comment on ..',
	},(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
		    is_loading:false
		})
	    }
	    /*
	    this.setState(prevState => ({
		comments: [...prevState.comments, newtxt],
		is_loading:false
	    }))
*/

	})
    }
    handle_click_history(){
	console.log('click history')
	this.props.cb_parent({
	    cmd: 'open-history',
	    acf_key: this.props.tup.key,
	    aspect: 'huidig'
	},()=>{})
    }
    get_value(e:any){
	if( e.name === "jaren_van_renovaties"){
	    let jaren = e.values.map((x:any) => x.jaar )
	    return jaren.join(", ")
	}else if(e.class === "renovaties"){
	    let rijen = e.values.map((x:any,j:number) => (
		<div className="row" key={j}>
		    <div className="label">{x.jaar}:</div>
		    <div className="body">{x.omschrijving}</div>
		    </div>
	    ))
	    return rijen
	}else if(e.name === "gasketel"){
	    let v = e.values.filter((x:any) =>
		(x.soort === "cv_ketel_alleen_verwarming"
		 ||
		 x.soort === "cv_combiketel"
		)
	    ).at(0)
	    if(v){
		let keys = ['Merk','Type','Jaar','Capaciteit','Toelichting', 'waar_hoevaak']
		return keys.map((k:string,j:number) => {
		    let lbl = (k === 'waar_hoevaak') ? 'Waar aanwezig en hoevaak gebruikt' : k
		    return (
		    
			<div className="label-value" key={j}>
			<span className="label"><i>{lbl}: </i></span>
			<span className="value">{v[k.toLowerCase()]}</span>
		    </div>
		    )})
	    }
	    
	}else{
	    return e.value
	}
    }
    render(){
	let tup = this.props.tup
	let url = ''
	var label = tup.label

	return (
	    <div className="collab-output section-part">
		<div className="header">
		    <div className="label">{tup.label} </div>
		</div>
		
		<div className="value"> { this.state.value } </div>
		<div className="calc-details">
		    <div className="variables">
			<div className="header-small">Rekenvariabelen: </div>
			<div className="content">
			    {tup.calc_vars.map((txt:string,i:number)=> (<div key={i}> {txt} </div>))}
			</div>
		    </div>
		    <div className="formula">
			<div className="header-small">Formule:</div>
			<div className="content">
			    {tup.formula.replace('MIN','[de laagste]')}
			</div>
		    </div>
		</div>
	    </div>
	)
    }
}

export default CalculatedOutput
