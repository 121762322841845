import * as React from 'react';

import Loco from './Loco'
import HdaStep from './HdaStep';
import Bouwdelen from "./Bouwdelen"
import DerivedValueStep from "./DerivedValueStep"
import V1Inputs from "./V1Inputs"
import CollabNumberInput from "./CollabNumberInput"

import SubSection from "./SubSection"

interface MyProps {
    post_id: string,
    user:any,
    tup: any,
    view:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    completed:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaSection extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)

	this.state = {
	    completed:false
	}
    }


    // send signal to parent ; to open the vote-grid
    public handle_cb(e:any, cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle cb")
	if(e.cmd === "set-section-status"){
	    console.log(e)
	    this.setState({ completed : (e.val === "ready")})
	}else{
	    this.props.cb_parent(e,(r:any)=>{
		console.log("result after vote:")
		console.log(r)
		// reverse signal to Step
		this.setState({ completed : (r.section_status === "ready")})
		this.callback_after_vote(r)
	    })
	}
    }
    public handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<div className="info-popup"><span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>{Loco.tr('click_for_info')}
		<i className="bb-icon-rl bb-icon-info"/>
		</span></div>
		)
	}
    }
    show_step(tup:any){
	if(this.props.view === "buildingmeasures"){
	    return tup.cftype === 'hda-bouwdelen-stap'
	}
	return true
    }
    componentDidMount(){
	this.setState({
	    completed: (this.props.tup.value === 'ready')
	})
    }
    public render(){
	if(this.props.tup.cftype === 'hda-section'){
	    return (
		<div className="section"  acf-key={this.props.tup.key}>
		    <div className="header">
		    <div className="label">
		    {this.props.view === "actionlist" ? (
			<span>{this.props.tup.cft_identifier}. </span>) : null}
		    {this.props.tup.label}
		       {this.state.completed ? ' - AFGEROND' : null}
		       {this.render_instr()}</div>
		    </div>
		    <div className="steps">
		    {this.props.tup.steps.map((tup:any, j:number) => {
			if(! this.show_step(tup)){
			    return null
			}
			if( tup.cftype === 'hda-choice-stap'){
			    return (<HdaStep
				    cb_parent={this.handle_cb}
				    permission={this.props.tup.permission}
				    key={j} tup={tup} post_id={this.props.post_id} />)
			}else if( tup.cftype === 'sub-section'){
			    //return this.render_subsection(j,tup)
			    return (<SubSection key={j} tup={tup} cb_parent={this.handle_cb}
				    permission={this.props.tup.permission}

				/>)
			}else if( tup.cftype === 'hda-derived-field'){
			    return (<DerivedValueStep tup={tup} key={j}/>)
			}else if( tup.cftype === 'hda-bouwdelen-stap'){
			    return (<Bouwdelen key={j}
				user={this.props.user}
				    permission={this.props.tup.permission}
				cb_parent={this.handle_cb}
				    post_id={this.props.post_id} tup={tup}  />)
			}else if( tup.cftype === 'v1-inputs-with-comment'){
			    return (<V1Inputs
				    tup={tup} key={j}
				    parent_label=""
				    cb_parent={this.handle_cb}
				/> )
			    /*
			}else if( tup.cftype === 'collab-number-input'){

			    return (<CollabNumberInput
				    subsection={null}
				    tup={tup} key={j}
				    cb_parent={this.handle_cb}
				/>
				)
			    */
			}else{
			    return null
			}
		    })}
		</div>		
  		    </div>
	    )
	}
    }
}
export default HdaSection;
