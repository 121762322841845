import * as React from 'react';

import Loader from './Loader'
import {advise_type, render_advise} from './Advise'
import BouwdeelAdvise from './BouwdeelAdvise'
import Loco from './Loco'
import User from './User'

interface MyProps {
    label:string,
    name:string,
    oordeel:any,
    user:any,
    advises: Array<any>,
    cb_parent: (e: any, cb: (r: any) => void) => void,
    tup:any
}
type MyState = {
    oordeel_comments: Array<string>,
    oordeel_comment_count: number,
    choice:string,
    value_lab:string,
    is_loading:boolean,
    advises: {[aid:string]:any}
};


class Bouwdeel extends React.PureComponent<MyProps,MyState> {
    private labels: { [string: string]: string } 	= {
	'huidig' : 'Oordeel over huidige situatie',
	'voorstel' : 'Mogelijke maatregelen / -pakketten'
    }

    constructor(props: MyProps) {
        super(props)

	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_value_click = this.handle_value_click.bind(this)
	this.handle_advise_click = this.handle_advise_click.bind(this)
	this.handle_advise_comment_click = this.handle_advise_comment_click.bind(this)
	this.state = {
	    is_loading:false,
	    value_lab: '',
	    oordeel_comments: [],
	    oordeel_comment_count:0,
	    choice:'',
	    advises: []

	}
    }
    handle_click_history(aspect:string){
	console.log(`click history ${aspect}`)

	let d = {
	    cmd: 'open-history',
	    acf_key: this.props.tup.key,
	    bouwdeel:this.props.name,
	    aspect: aspect,
	    choices: []
	}
	if(aspect === 'huidig'){
	    d.choices =  this.props.tup.opt_lists.oordeel
	}
		
	this.props.cb_parent(d,()=>{})
	
    }
    popup_label(){

	return this.props.tup.section_label
	    + ' - Bouwdeel: ' + this.props.label
    }
    public handle_comment_click(e:any){
	// open the vote-popup
	console.log('open popup comment-vote');
	

	this.props.cb_parent({
	    cmd: 'comment-vote',
	    acf_key_step: this.props.tup.key,
	    bouwdeel:this.props.name,
	    label: this.popup_label(),
	    aspect: 'huidig'
	}, (r:any)=>{
	    console.log('callback');
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		if(r.new_state){
		    this.setState({
			oordeel_comments: r.new_state.map((x:any)=>x.value),
			is_loading:false
		    })
		}
	    }
	})
    }
    handle_advise_comment_click(attr_id:string, cb:(r:any)=>void){
	console.log(`advise-comment-click ${attr_id}`)
	let adv :any = this.state.advises[attr_id]

	this.props.cb_parent({
	    cmd: 'advise-comment-vote',
	    attr_id: attr_id,
	    label: this.popup_label(),
	    top3: adv.comments
	}, (r:any)=>{
	    // callback is received twice:
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		console.log('callback with r')
		if(r.bd_advise){
		    console.log('merge returned data into state.advises')
		    let k = r.on_attr_id
		    if(k){
			let newtup = r.bd_advise[k]
			this.setState(prevState => {
			    let adv = Object.assign({}, prevState.advises)   // creating copy of state variable jasper
			    adv[k] = newtup
			    return { advises: adv, is_loading:false}
			})
		    }else{
			console.log('no on_attr_id')
		    }
		}else{
		    this.setState({is_loading:false})
		    if(r.votetype === 'add_comment'){
			// oude route.. kan denk ik vervallen
			// callback renders new comment in DOM
			cb({ txt: r.winner_txt})
		    }
		}
	    }
	})
    }
    handle_advise_click(e:any){
	console.log('advise')
	this.props.cb_parent({
	    cmd: 'advise-vote',
	    acf_key_step: this.props.tup.key,
	    label: this.popup_label(),

	    bouwdeel:this.props.name,
	    aspect: 'voorstel',
	    top3: Object.keys(this.state.advises)
	}, (r:any)=>{
	    // callback is received twice:
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		// 2. the API-call is finished, with result
		console.log(r)
		if(r.bd_advise){
		    this.setState({advises:r.bd_advise, is_loading:false})
		}
		/*
		if(r.winner_txt !== undefined){
		    console.log(r.winner_txt)
		    let newtup = {
			advise:{
			    attr_id: r.value,
			    value: JSON.parse( r.winner_txt)
			},
			comments: []
		    }
		    this.setState(prevState => ({
			advises: [...prevState.advises, newtup],
			is_loading:false
		    }))
		}

		if(r.new_txt !== undefined){
		    console.log('changed your advise')
		    console.log(` attr-id ${r.attr_id}`)
		    let adv = JSON.parse(r.new_txt)
		    // FIXME: update new gain+price+text in BouwdeelAdvise
		    //  evt kan je vanuit BouwdeelAdvise een callback down
		    //  probleem is.. de popup wordt niet alleen geopend vanuit BouwdeelAdvise
		    //  maar ook onderaan via een knop met onClick={this.handle_advise_click)
		    // Idee: de knop onderaan geeft alleen toegang tot een 'Add new' popup
		}
		*/
	    }
	})
    }

    public handle_value_click(e:any){
	console.log(this.props.tup)
	// open the vote-popup 'Oordeel'
	console.log('value-click Oordeel')
	let pop_label = (<span>{this.props.tup.section_label}. Geef je oordeel over <i>Bouwdeel: {this.props.label}</i></span>)
	this.props.cb_parent({
	    cmd: 'choice-vote',
	    acf_key_step: this.props.tup.key,
	    label: pop_label,
	    bouwdeel:this.props.name,
	    aspect: 'huidig'
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		this.setState({
		    is_loading:false,
		    choice: r.value,
		    value_lab: this.props.tup.opt_lists.oordeel[r.value]
		})
	    }
	})
    }
    componentDidMount(){
	let value = this.props.oordeel.choice
	let choices = this.props.tup.opt_lists.oordeel
	let lab = choices[value]
	/*
	var gain = ''
	var price = ''
	if(Object.keys(this.props.advise).length > 0){
	    gain = this.props.advise.gain
	    price = this.props.advise.price
	}
	*/
	    

	this.setState({
	    choice: value,
	    oordeel_comments: this.props.oordeel.comments.map((x:any)=>x.value),
	    oordeel_comment_count: this.props.oordeel.comment_count,
	    value_lab: lab,
	    advises: this.props.advises
	})

    }
    
    render_oordeel(){
	// oordeel
	let aspect = 'huidig'
	let perm = User.tup.permissions.default
	let has_edit_perm = perm[aspect] === 'rw'
	let col1 = has_edit_perm ? (
	    <div className="winner" onClick={this.handle_value_click}>
	        <div className="label">{this.state.value_lab}</div>
                <span data-balloon="Keuze en details" data-balloon-pos="up"><i className="bb-icon-f bb-icon-ellipsis-h" /> </span>
	    </div>
	) : (
	    <div className="winner" >
	        <div className="label">{this.state.value_lab}</div> </div>
	)
	let col2 = has_edit_perm ? (
	    <div className="right-col remarks" onClick={this.handle_comment_click} >
		<div className="text-with-btn default">
                    <div className="text">
			{this.render_oordeel_comments()}
		    </div>
		<span className="cf-count-btn"
			data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.oordeel_comment_count}</span>

		</div>
             </div>
	) : (
	    <div className="right-col remarks" >
                <div className="text">
		    {this.render_oordeel_comments()}
		</div> </div>

	)
	return (
	    <div className="two-columns">
		{col1} {col2}
	    </div>
	)
    }
    render_oordeel_comments(){
	if(this.state.oordeel_comments === undefined){
	    return (<div className="text">   </div>)
	}else{
	    return (<div className="text">
			{this.state.oordeel_comments.map((txt:string,j:number)=> {
			    let k = `t-${j}`
			    return (<div key={k} className="part" >{txt}</div>)
			})}
		    </div>
		   )
	}
    }

    render_advises(){


    	return (
	    <div className="advises">
		{Object.entries(this.state.advises).map(([attr_id, tup]: [string, any]) => (
		    <BouwdeelAdvise
		    attr_id={attr_id}
		    value={tup.value}
		    comments={tup.comments}
		    comment_count={tup.comment_count}
		    handle_adv_comment_click={this.handle_advise_comment_click}
		    handle_advise_click={this.handle_advise_click}
		     key={attr_id} />
		))}
    
		<div className="buttons  two-columns">
		<div >
		<span className="cf-button add-advise" onClick={this.handle_advise_click}>{Loco.tr('add_advise')}</span>
		</div> <div></div>
		</div>
	    </div>
	)
    }	       
	

    render_aspect(aspect:string){
	let label = this.labels[aspect]
	return (
	<div className="aspect">
	    <div className="header">
		<div className="label"> {label} </div>
		<span data-balloon="Details / keuze"
	           data-balloon-pos="up" className="cf-icon-btn"
	    onClick={()=>this.handle_click_history(aspect)}><i className="bb-icon-l bb-icon-list"/></span>
		
 	    </div>
	    <div>
		{aspect === 'huidig' ? this.render_oordeel() : this.render_advises()}
	    </div>
	</div>
	)
    }

    render(){
	let slab = this.props.tup.section_label
	return (
	    <div className="bouwdeel">
		
		<div className="label"><span className="slab">{slab}</span> - Bouwdeel: {this.props.label}</div>
		{this.state.is_loading ? <Loader/> : null}
		<div className="aspects zwadvies">
		{this.render_aspect('huidig')}
	    {this.render_aspect('voorstel')}


		</div>
	    </div>
	)
    }
}
export default Bouwdeel
