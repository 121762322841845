import * as React from 'react'
import Loco from './Loco'
import SupplierQuotationAspect from './SupplierQuotationAspect'



interface MyProps {
    status_options:any,
    row:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void    
}
type MyState = {

}
class SupplierQuotationsListRow extends React.PureComponent<MyProps,MyState> {
    
    row_click_handler(e:any){
	console.log('row-click')
	let qel = e.target.closest('.quotation');

	let is_collapsed = qel.classList.contains('collapsed');
	
	console.log(' is collapsed ?  ' + (is_collapsed ? 'y' : 'n'))
	document.querySelectorAll('.quotation').forEach((x)=>x.classList.add('collapsed'))
	console.log(qel)
	if(is_collapsed){
	    qel.classList.remove('collapsed')
	}else{
	    qel.classList.add('collapsed')
	}
    }
    render_expand(row:any){

	let has_edit_perm = true
	let con = this.get_contact(row)
	let contact = con.first_name + ' ' + con.last_name
	
	let fields = {
	    'Aangevraagd': row.date,
	    'Aanvrager': contact,
	    'Adres' : row.hd.title,
	    'Plaats' : row.hd.plaats,
	    'Postcode': row.hd.postcode,
	    'Mobiel': con.mobiele_telefoon,
	    'Email': con.email,
	    'Maatregel-type': row.maatregel_type,
	    'Maatregel': row.maatregel_label
	}
	return (
	    <div className="expand">
		<div className="fields">
		    {Object.entries(fields).map(([fname,fvalue]:[string,any],i:number) => {
			return (
			    <div key={i} className="field">
				<div className="label">{fname}:</div>
				<div className="value">{fvalue}</div>
			    </div>)
		    })}
	        </div>
		
		{this.render_aspect('huidig',row)}
		{this.render_aspect('voorstel',row)}
	    </div>
	)
    }
    render_aspect(aspect:string,row:any){
	let has_edit_perm = (aspect === 'voorstel')
	return (
	    <SupplierQuotationAspect
	    aspect={aspect}
	    cb_parent={this.props.cb_parent}
	    status_options={this.props.status_options}
	    tup={row} />
	)

    }
    get_status_label(val:string){
	let choices = this.props.status_options.choices
	if(val !== null){
	    return choices[val];
	}
    }
    get_contact(row:any){
	let con = row.hd.contact
	if(con == null){
	    return {
		first_name:'',
		last_name:'',
		email:''
	    }
	}
	return con
    }
    render(){
	let row = this.props.row
	    let label1 = this.get_status_label(row.huidig.status)
	    let label2 = this.get_status_label(row.voorstel.status)
	let con = this.get_contact(row)
	let contact = con.first_name + ' ' + con.last_name
	    return (
		<div  className="quotation collapsed">
		    <div className="row" onClick={this.row_click_handler}>
			<div className="pre-icon"></div>
			<div className="date">{row.date}</div>
		    <div className="contact" title={contact}>{contact}</div>
			<div className="adres" title={row.hd.title}>{row.hd.title}</div>
			<div className="postcode">{row.hd.postcode}</div>
			<div className="maatregeltype" title={row.maatregel_type}> {row.maatregel_type}</div>
			<div className="maatregel" title={row.maatregel_label}> {row.maatregel_label}</div>
			<div className="status1" title={label1}>{label1}</div>
			<div className="status2" title={label2}>{label2}</div>
		    </div>
		    
		    {this.render_expand(row)}
		    
		</div>
	    )
    }
}

export default SupplierQuotationsListRow
