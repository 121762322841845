import * as React from 'react';

import WpApi from './wpapi'
import Loader from './Loader'
import Loco from './Loco'
import User from './User'
import Conditional_dropdown from './Conditional_dropdown'

interface MyProps {
    aspect: string,  // aspect : huidig | voorstel
    tup: any,
    label:string,
    permission:any,
    stup:any,
    post_id:string,
    acfkey:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    value_lab:string,
    value_count:number,
    winner: string,
    comments: Array<string>,
    comment_count: number,
    buttons: Array<any>,
    is_loading:boolean
};

class StepAspect extends React.PureComponent<MyProps,MyState> {
    
    private labels: { [string: string]: string } 	= {
	'huidig' :  Loco.tr('header_huidig'),
	'huidig_zwadvies': Loco.tr('header_huidig_zwadvies'),
	'voorstel' : Loco.tr('header_voorstel'),
    }
	

    constructor(props: MyProps) {
        super(props)
        this.handle_value_click = this.handle_value_click.bind(this)
        this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)
	this.handle_button_click = this.handle_button_click.bind(this)
	//this.render_choice = this.render_choice.bind(this)
	this.handle_dropdown_change = this.handle_dropdown_change.bind(this)
	this.state = {
	    value_lab : '',
	    value_count:0,
	    winner: '',
	    comments:[],
	    comment_count:0,
	    buttons: [],
	    is_loading:true
	}
    }
    get_pop_label(){
	//let label = this.props.stup.label
	let slab = this.props.stup.section_label
	var aspect = this.props.aspect
	var alabel = this.labels[aspect]
	if(this.props.label){
	    return (<span>{alabel}: {this.props.label}</span>)
	}else{
	    return alabel + ': ' + slab
	}
    }
    public handle_comment_click(e:any){
	// open the vote-popup for comments
	let lbl = this.get_pop_label()
	this.props.cb_parent({
	    cmd: 'comment-vote',
	    //tup:this.props.tup,
	    label: lbl,
	    acf_key_step: this.props.acfkey,
	    acf_key_select: this.props.tup.select.key,
	    aspect: this.props.aspect
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		//this.setState({comment: r.winner_txt, is_loading:false})
		console.log('set comments?')
		if(r.new_state){
		    this.setState({
			comments: r.new_state.map((x:any)=>x.value),
			is_loading:false
		    })
		}
	    }
	})
    }
    public handle_value_click(e:any){
	let lbl = this.get_pop_label()
	console.log(e)
	// open the vote-popup
	this.props.cb_parent({
	    cmd: 'choice-vote',
	    //tup:this.props.tup,
	    label: lbl,
	    acf_key_step: this.props.acfkey,
	    acf_key_select: this.props.tup.select.key,
	    is_final_field: this.props.tup.is_final_field,
	    aspect: this.props.aspect
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		this.update_choice_winner( r.winner)
	    }
	})
    }

    update_choice_winner(value:string){
	console.log('update choice-winner')
	this.setState({is_loading:false})
	if(value in this.props.tup.choices){
	    console.log(` value ${value}`)
	    var lab = value === 'kies' ? '' : this.props.tup.choices[value]
	    this.setState({
		winner: value,
		value_count: this.props.tup.value_count,
		value_lab : lab
	    })
	}
	if(value==null){
	    console.log('value is nul')
	    this.setState({
		winner:'',
		value_lab:'',
		value_count:0
	    })
	}
    }



    componentDidMount(){
	// update state from props
	let atup = this.props.tup
	if(atup.active){

	    if('buttons' in atup){
		this.setState({buttons: atup.buttons.list})
	    }
	    if('value' in atup){
		this.update_choice_winner(atup.value)
	    }
	    if('comments' in atup){
		let txt_array = this.props.tup.comments.map((x:any)=>x.value)
		this.setState({
		    comments: txt_array,
		    comment_count: this.props.tup.comment_count
		})
	    }



	}

    }
    public handle_click_history(){
	console.log('click history')
	this.props.cb_parent({
	    cmd: 'open-history',
	    choices:this.props.tup.choices,
	    acf_key: this.props.acfkey,
	    aspect: this.props.aspect
	},()=>{})

    }
    handle_dropdown_change(e:any){
	let slug = e.target.value
	console.log(e.target)
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    acf_key: this.props.acfkey,
	    votetype: 'choice',
	    aspect: this.props.aspect,
	    post_id : this.props.post_id,
	    is_final_field: this.props.tup.is_final_field,
	    value: slug
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})

	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.update_choice_winner( r.winner)
	    this.props.cb_parent({
		cmd: 'set-section-status',
		val: r.section_status
	    },()=>{})

	})
	
    }
    public handle_button_click(e:any,el:any){
	let slug = el.name
	console.log(`button click ${slug}`)

    }
    /*
    public render_button(el:any,i:number){

	let logic = el.conditional_logic
	var show = false
	if(logic){
	    for(var rule of logic){
		for(var r of rule){
		    if(this.state.winner === r.value){
			show = true
		    }
		}
	    }
	}
	if(show){
	    return (
		<div key={i}><span onClick={(e:any)=>{this.handle_button_click(e,el)}}
		  className="cf-button" key={i}>{el.label} </span></div>
	    )
	}
    }
    */

    public render_dropdown(){
	let showall = this.props.tup.buttons.class === 'show-all-buttons'
	let placeholder = this.props.aspect == 'huidig' ? '-wijzig status-' : '-wijzig advies-'
	return (new Conditional_dropdown()).select_with_cond_logic(
	    this.state.buttons,
	    showall,
	    this.state.winner,
	    placeholder,
	    this.handle_dropdown_change
	)
    }

    public render(){
	let atup = this.props.tup
	if(atup.active){
	    return this.render_aspect()
	}else{
	    return (<div> </div>)
	}
    }
    render_comments(){
	return (<div className="text">
				{this.state.comments.map((txt:string,j:number)=> {
				    let k = `t-${j}`
				    return (<div key={k} className="part" >{txt}</div>)
				})}
		</div>)
    }
    
    render_aspect(){
	let stup = this.props.stup
	var aspect = this.props.aspect
	let zw_a_label = this.labels.huidig_zwadvies;
	var label = (aspect === 'huidig' && stup.section_name == 'zw-advies') ? zw_a_label : this.labels[aspect]
	var loader = (<Loader/>)
	let has_edit_perm = false;
	let has_view_perm = false;
	if(this.props.permission && this.props.permission.edit){
	    has_edit_perm = this.props.permission.edit.current_user;
	    has_view_perm = this.props.permission.view.current_user;
	}
	if(stup.section_name == 'zw-advies'){
	    if(User.tup.member_type == 'wijkgenoot'){
		has_edit_perm = false
	    }
	}

	if(aspect === 'voorstel' && ! has_edit_perm){
	    if(this.state.value_count == 0 && this.state.comment_count == 0){
		return (<div>  </div>)
	    }
	}
	if(! has_view_perm){
	    return (
	    	<div className="aspect">
		    <div className="header">
			<div className="label"> - -  </div> <span>- </span>
		    </div>
		    <div className="two-columns">  </div>
		</div>
	    )
	}

	return (
	    <div className="aspect">
	      <div className="header">
		  <div className="label"> {label} </div>
		  <span data-balloon="Toon historie"
			data-balloon-pos="up" className="cf-icon-btn"
			onClick={this.handle_click_history}><i className="bb-icon-l bb-icon-list"/></span>
		  
	      </div>
	      {this.state.is_loading ? loader : null }
		<div className="two-columns">
		    {has_edit_perm ? (
			
			<div className="left-col">
			    <div className="winner" onClick={this.handle_value_click}>
				<div className="label">
				    {this.state.value_lab ==='' ? Loco.tr('no_value_yet'):this.state.value_lab}
				</div>
			    	<span className="cf-count-btn"
				      data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.value_count}</span>

				
         		    </div>
			</div>
		    ) : (
			<div className="left-col">
			    <div className="winner readonly">
				<div className="label">
				    {this.state.value_lab ==='' ? Loco.tr('no_value_yet'):this.state.value_lab}
				</div>
			    </div>
			</div>
			)}
		    {has_edit_perm ? (
			<div className="right-col cfdrop" >
			    {this.render_dropdown()}
			</div>
		    ) : null }
		</div>
		
		<div className="remarks" >
		    {has_edit_perm ? (
			<div className="text-with-btn" onClick={this.handle_comment_click}>
			    {this.render_comments()}
			    <span className="cf-count-btn"
			data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>
			</div>
		    ) : 
			this.render_comments()
		    }
		</div>
		
	    </div>
	)
    }
}
/*
*/

export default StepAspect;
