import * as React from 'react'

import WpApi from './wpapi'
import Loader from './Loader'
import QuotationStepAspect from  './QuotationStepAspect'

interface MyProps {
    tup:any,
    postid:number,
    is_simple_block:boolean,
    status_options:any,
    status_buttons:any,
    permission:any,
    simple_options:any,
    bouwdeel_options:any,
    actiestappen:any,
    maatregeltype:any,
    supplier_choices:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    supplier:string
    supplier_choices: any,
    is_loading:boolean,
    value:string
    
}
class QuotationStep extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_select_supplier = this.handle_select_supplier.bind(this)
	this.handle_select_bouwdeel = this.handle_select_bouwdeel.bind(this)
	this.state = {
	    supplier:'',
	    value:'',
	    supplier_choices:{},
	    is_loading:false
	}
    }
    handle_simplerow_click(row:any){
	console.log('clicked')
	let slug = row.name

	this.props.cb_parent({
	    cmd: 'set-quotation-status',
	    value: slug,
	    on_attr_id: this.props.tup.attr_id,
	    acf_key: this.props.status_options.key,
	},(r:any)=>{
	    if(r.winner){
		console.log(`got  winner ${r.winner}`)
		this.setState({
		    value: r.winner,
		    is_loading:false
		})
	    }
	})
    }
    
    handle_select_supplier(e:any){
	console.log('selected supplier')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.postid,
	    votetype: 'choice-on-attr',
	    value: slug,
	    aspect: 'huidig',
	    acf_key: this.props.supplier_choices.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	    on_attr_id: this.props.tup.attr_id,
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		supplier:r.winner
	    })
	})
    }
    handle_select_bouwdeel(e:any){
	console.log('selected bouwdeel')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.postid,
	    votetype: 'choice-on-attr',
	    value: slug,
	    aspect: 'huidig',
	    acf_key: this.props.bouwdeel_options.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	    on_attr_id: this.props.tup.attr_id,
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		supplier:r.winner
	    })
	})
	
    }
    filtered_supplier_choices(){
	let choices = this.props.supplier_choices.choices
	// FIXME: conditional logic hardcoded; dit is een tijdelijke oplossing.
	// Verbergt supplier-opties per maatregeltype
	//   bijv. maatregeltype 'meterkast' heeft NIET de optie 'liander'
	let hide_options : {[index:string]: Array<string>} = {
	    'meterkast' : [ 'liander'],
	    '3fase' : ['onbepaald']
	}
	var skip :Array<string> = []
	let slug = this.props.maatregeltype.slug
	if(hide_options[slug]){
	    for(var x of hide_options[slug]){
		skip.push(x);
	    }
	}

	let ret :{[name:string]:string}= {};
	for(let k in choices){
	    let v = choices[k];
	    if(! skip.includes(k)){
		ret[k] = v;
	    }
	}
	return ret;
    }
    componentDidMount(){
	let atup = this.props.tup

	this.setState({
	    supplier_choices: this.filtered_supplier_choices(),
	    value: atup.huidig.status,
	    supplier: atup.supplier

	})
    }
    render_bouwdeel_dropdown(){
	let choices = this.props.bouwdeel_options.choices
	return (
	    <div className="choose-bouwdeel">
		<select style={{height:'unset'}} onChange={this.handle_select_bouwdeel}>
		<option value="">-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)
		})}
	    </select>
	    </div>
	)
	

    }
    render_supplier_dropdown(){
	let choices = this.state.supplier_choices
	let supplier = this.state.supplier
	if(supplier){
	    let label = choices[supplier]
	    return (
		<div className="choose-supplier">{label}</div>
	    )
	}
	return (
	    <div className="choose-supplier">
		<select style={{height:'unset'}} onChange={this.handle_select_supplier}>
		<option value="">-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)
		})}
	    </select>
	    </div>
	)
    }
    render_simple_block_rows(){
	let all_rows = this.props.simple_options.buttons;
	let winner = this.state.value
	let rows = all_rows.filter((el:any)=>{
	    let logic = el.conditional_logic
	    var show = false
	    if(logic){
		for(var rule of logic){
		    for(var r of rule){
			if(winner === r.value){
			    show = true
			}
		    }
		}
	    }
	    return show
	})
	
	return rows.map((row:any,i:number) =>{
	    return (
		<div className="row" key={i}>
		    {row['cft-identifier'] === 'button'
		     ? (
			 <button onClick={(e)=>{this.handle_simplerow_click(row)}}>
			     {row.label}
			 </button>
		     )
		     : (
			 <div>{row.label}</div>
		     )
		    }
		    </div>

	    )
	})
    }
    render_simple_block(){
	let supplier = this.state.supplier
	if(supplier){
	    return (
		<div className="simple-block">
		    <div className="rows">
			{this.render_simple_block_rows()}
		    </div>
		</div>
	    )
	}
    }
    render(){
	let tup = this.props.tup
	/*
	let choices = this.props.maatregeltype.choices;
	let mtype = {
	    slug: tup.value,
	    label: choices[tup.value]
	}
	*/
	let mtype = ''

/*
		<div className="header-sub">
		    <div className="label">Betreft bouwdeel:</div>
			<div className="value">{this.render_bouwdeel_dropdown()} </div>
		    </div> 
		</div>
*/

    	return (
	    <div className="step quotation">
		<div className="top-el">
		    <div className="header-main">
			<div className="label">
			    Offerte voor welke maatregel: {this.props.maatregeltype.label }
			</div>
		    </div>
		    <div className="header-sub">
			<div className="label">Gericht aan welke Leverancier:</div>
			<div className="value">{this.render_supplier_dropdown()} </div>
		</div>
		</div>
		{this.props.is_simple_block ? this.render_simple_block() : (
		<div className="aspects default">
		    <QuotationStepAspect
		    aspect='huidig'
		    permission={this.props.permission.huidig}
			on_attr_id={this.props.tup.attr_id}
			status_options={this.props.status_options}
			status_buttons={this.props.status_buttons}
			actiestappen={this.props.actiestappen}
			postid={this.props.postid}
			cb_parent={this.props.cb_parent}
			tup={tup.huidig}
		    />
		    <QuotationStepAspect
		    aspect='voorstel'
		    		    permission={this.props.permission.voorstel}
			on_attr_id={this.props.tup.attr_id}
			status_options={this.props.status_options}
			status_buttons={this.props.status_buttons}
			actiestappen={this.props.actiestappen}
			postid={this.props.postid}
			cb_parent={this.props.cb_parent}
			tup={tup.voorstel}
		    />

			</div>
		)}
	    </div>
	    
		
	)
    }
}
export default QuotationStep;

