import * as React from 'react';

import StepAspect from "./StepAspect"

interface MyProps {
    post_id: string,
    tup: any,
    permission:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
};

interface cb_after_vote {
    (r:any): void;
}

class HdaStep extends React.PureComponent<MyProps,MyState> {
    
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}


    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
    }

    // send signal to parent ; to open the vote-grid
    // pass-on the signal from StepAspect
    handle_cb(e:any,cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle-cb")
	/*
	let label_txt = this.props.tup.label
	let slab = this.props.tup.section_label
	e.label = label_txt + ' ' + slab
	*/
	this.props.cb_parent(e,(r:any)=>{
	    console.log("received:")
	    console.log(r)
	    // reverse signal to StepAspect
	    this.callback_after_vote(r)
	})
    }
    public handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span>
		)
	}
    }
    public render(){
	let label_txt = this.props.tup.label
	let label_txt2 = this.props.tup.label
	let slab = this.props.tup.section_label
	//let m = /^([A-Z0-9]+)[ .]+(.*)/.exec(slab)
	let m = /^([^{]+){parent}/.exec(this.props.tup.label)


	if(m){
	    console.log('rematch')
	    console.log(m)
	    let lab1 = m[1].trim()
	    //label_txt = m[1] + slab
	    label_txt = (<span>{lab1}: <i>{slab}</i></span>)
	    label_txt2 = (<span>{lab1} <i>{slab}</i></span>)
	}

	
	let label = (this.props.tup.label !== '' ? (
	    <div className="label">
		{label_txt}
		{this.render_instr()}
	    </div>
	) :  null)

	return (
	    <div className="step" acf-key={this.props.tup.key}>
		{label}
		<div className="aspects default">
		    <StepAspect
			key='huidig' post_id={this.props.post_id}
			label={label_txt2}
			stup={this.props.tup}
			permission={this.props.permission.huidig}
			aspect='huidig' tup={this.props.tup.huidig} acfkey={this.props.tup.key}
			cb_parent={this.handle_cb} />
		    <StepAspect
			key='voorstel' post_id={this.props.post_id}
			label={label_txt2}
			stup={this.props.tup}
			permission={this.props.permission.voorstel}
			aspect='voorstel' tup={this.props.tup.voorstel} acfkey={this.props.tup.key}
			cb_parent={this.handle_cb} />
		</div>
	      </div>
	)
    }
}
export default HdaStep;
