import * as React from 'react'

import * as ReactDOMServer from 'react-dom/server';
import IconLink from './IconLink'

interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    parent_label:string,
    tup:any
}
type MyState = {
    is_loading:boolean,
    comment_count:number,
    comments: Array<string>
}

class V1Inputs extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)
	this.state = {
	    comments: [],
	    comment_count:0,
	    is_loading:false
	}
    }
    componentDidMount(){

	if(this.props.tup.comment){
	    let c = this.props.tup.comment
	    this.setState({comments:c.map((x:any)=>x.value)})
	}
    }

    handle_comment_click(){
	console.log('comments-clicked')
	let lbl = 'Opmerkingen bij: ' + this.props.parent_label + ': ' + this.props.tup.label
	this.props.cb_parent({
	    cmd: 'inputs-comment-vote',
	    acfkey: this.props.tup.key,
	    aspect:'huidig',
	    label: lbl
	},(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
		    is_loading:false
		})
	    }
	    /*
	    this.setState(prevState => ({
		comments: [...prevState.comments, newtxt],
		is_loading:false
	    }))
*/

	})
    }
    handle_click_history(){
	console.log('click history')
	this.props.cb_parent({
	    cmd: 'open-history',
	    acf_key: this.props.tup.key,
	    aspect: 'huidig'
	},()=>{})
    }
    get_value(e:any){
	if( e.name === "jaren_van_renovaties"){
	    let jaren = e.values.map((x:any) => x.jaar )
	    return jaren.join(", ")
	}else if(e.class === "renovaties"){
	    let rijen = e.values.map((x:any,j:number) => (
		<div className="row" key={j}>
		    <div className="label">{x.jaar}:</div>
		    <div className="body">{x.omschrijving}</div>
		    </div>
	    ))
	    return rijen
	}else if(e.name === "gasketel"){
	    let v = e.values.filter((x:any) =>
		(x.soort === "cv_ketel_alleen_verwarming"
		 ||
		 x.soort === "cv_combiketel"
		)
	    ).at(0)
	    if(v){
		let keys = ['Merk','Type','Jaar','Capaciteit','Toelichting', 'waar_hoevaak']
		return keys.map((k:string,j:number) => {
		    let lbl = (k === 'waar_hoevaak') ? 'Waar aanwezig en hoevaak gebruikt' : k
		    return (
		    
			<div className="label-value" key={j}>
			<span className="label"><i>{lbl}: </i></span>
			<span className="value">{v[k.toLowerCase()]}</span>
		    </div>
		    )})
	    }
	    
	}else{
	    return e.value
	}
    }
    handle_ref_link(e:any){
	console.log(e)
	let html = (
	    <div>
		Zie: <a target="_blank" href={e.url}> {e.label}
	      <IconLink/>
	    </a>
		<br/> in {e.group}
	    </div>
	)
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: ReactDOMServer.renderToString(html),
	},()=>{})
    }
    render(){
	let tup = this.props.tup
	let url = ''
	var label = tup.label
	if(tup.subfields.length > 0){
	    let s = tup.subfields.at()
	    if(s.ref_link){
		label = (<div className="linkref" onClick={()=>{this.handle_ref_link(s.ref_link)}}> {label} </div>)
	    }
	}
	return (
	    <div className="v1-inputs-with-comment section-part">
		<div className="header">
		<div className="label">{label} </div>
		    <span data-balloon="Toon historie"
			  data-balloon-pos="up" className="cf-icon-btn"
			  onClick={this.handle_click_history}><i className="bb-icon-l bb-icon-list"/></span>
		</div>
		
		<div className="two-columns">
		<div className="inputs">

		{tup.subfields.map((e:any,j:number)=>{

		    let lab = e.label === "" ? "" : (<i>{e.label}:</i>)
		    let val = this.get_value(e)
		    let k = "v-"+j
		    if(typeof val === "number" ||  (val && val.length > 0 && e.value_raw !== "kies")){
			return (<div key={k} className="value" > {lab} {val} </div>) //
		    }
		})}
		    </div>
		    <div className="remarks"  onClick={this.handle_comment_click} >
			<div className="text-with-btn">
			    <div className="text">
				{this.state.comments.map((txt:string,j:number)=> {
				    let k = "t-"+j
				    return (<div key={k} className="part" >{txt}</div>)
				})}
			    </div>
			    <span className="cf-count-btn"
				  data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>
			</div>
		    </div>
		</div>
	    </div>
	)
    }
}

export default V1Inputs
