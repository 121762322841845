import * as React from 'react';


interface MyProps {
    tup:any
}
type MyState = {
}

class DerivedValueStep extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
    }
    render(){
	let tup = this.props.tup

	return (
	    <div className="step">
		<div className="label">
		    <span>{tup.label} </span>

		</div>
		<div className="derived-value"> {tup.value}</div>
	      
	    </div>
	)
	
    }
}
export default DerivedValueStep
