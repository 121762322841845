import * as React from 'react';
import Loader from './Loader'

import {advise_type, render_advise} from './Advise'

interface MyProps {
    attr_id:string,
    comments: Array<string>,
    comment_count:number,
    handle_adv_comment_click: (a:string, cb: (r: any) => void) => void,
    handle_advise_click: (e: any) => void,
    value:any
}
type MyState = {
    is_loading:boolean,
    comment_count:number,
    comments: Array<string>
}
class BouwdeelAdvise extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_advise_click = this.handle_advise_click.bind(this)
	this.state = {
	    is_loading:false,
	    comment_count:0,
	    comments: []
	}
    }
    handle_comment_click(e:any){
	console.log('comments-clicked')
	//cmd: 'advise-comment-vote',
	this.props.handle_adv_comment_click(this.props.attr_id,(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    this.setState(prevState => ({
		comments: [...prevState.comments, newtxt],
		is_loading:false
	    }))
	})
    }
    
    componentDidMount(){

	this.setState({
	    comments: this.props.comments,
	    comment_count: this.props.comment_count
	})
    }

    handle_advise_click(e:any){
	console.log('advise')
	this.props.handle_advise_click({
	    cmd: 'advise-vote',
	})
    }
    componentWillReceiveProps(props:any) {
	this.setState({ comments: props.comments })
    }

    render(){
	
	return (
	    <div className="bd-advise two-columns">
		{this.state.is_loading ? <Loader/> : null}
		<div className="advise" onClick={this.handle_advise_click}> 
	    	<div className="text">  {render_advise(this.props.value)}</div>
	    		<span data-balloon="Details / keuze"
	           data-balloon-pos="up" className="cf-icon-btn"><i className="bb-icon-f bb-icon-ellipsis-h"/></span>

		</div>
		<div className="remarks"  onClick={this.handle_comment_click} >
		    <div className="text-with-btn">
			<div className="text">
		{this.state.comments.map((tup:any,i:number)=> ( <div className="part" key={i}> {tup.value} </div>))}
			</div>
			<span className="cf-count-btn"
			      data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>

		    </div>
		</div>
		
	    </div>
	)
    }
}

export default  BouwdeelAdvise
