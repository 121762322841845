import * as React from 'react';
import CollabNumberInput from "./CollabNumberInput"
import Loco from "./Loco"

interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    stup:any,
    permission:any,
    tup:any
}
type MyState = {
}

class CollabNumberInputWithAdvise extends React.PureComponent<MyProps,MyState> {

    render(){
	let tup = this.props.tup
	let label_1 = Loco.tr('according_to_owners') 
	    
	let label_2 =  Loco.tr('according_to_advisers')

	return (
	    <div className="input-with-advise section-part number">
		<CollabNumberInput
		    aspect="huidig"
	    	    permission={this.props.permission.huidig}
		    label={label_1}
		    parent_label={this.props.stup.label}
		    tup={tup.huidig}
		    pattern={tup.pattern}
		    select={tup.select}
		    cb_parent={this.props.cb_parent}
		/>
		<CollabNumberInput
		    label={label_2}
		    parent_label={this.props.stup.label}
		    aspect="voorstel"
	    	    permission={this.props.permission.voorstel}
		    tup={tup.voorstel}
	    	    pattern={tup.pattern}
		    select={tup.select}
		    cb_parent={this.props.cb_parent}
		/>

	    </div>
	)

    }
}

export default CollabNumberInputWithAdvise
