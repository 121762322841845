import * as React from 'react';
import Loco from "./Loco"
import User from "./User"

interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    aspect:string,
    permission:any,
    select:any,
    label:string,
    parent_label:string,
    tup:any
}
type MyState = {
    is_loading:boolean,
    comments: Array<string>,
    comment_count:number,
    values: {[aid:string]:any}
}

class CollabChoiceInput extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.handle_input_click = this.handle_input_click.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)
	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.state = {
	    is_loading:false,
	    values:{},
	    comment_count:0,
	    comments:[]
	}
    }
    componentDidMount(){
	if(this.props.tup.values){
	    let vals = this.props.tup.values

	    let txt_array = this.props.tup.comments.map((x:any)=>x.value)

	    this.setState({
		values: vals,
		comments: txt_array,
		comment_count: this.props.tup.comment_count
	    })
	}
    }
    handle_click_history(){
	console.log('click history')
	this.props.cb_parent({
	    cmd: 'open-history',
	    acf_key: this.props.tup.key,
	    choices: this.props.select.choices,
	    aspect: this.props.aspect,
	},()=>{})
    }
    handle_comment_click(){
	console.log('comments-clicked')
	let lbl =   this.props.parent_label + ': Opmerkingen over: ' +this.props.label
	this.props.cb_parent({
	    cmd: 'inputs-comment-vote',
	    acfkey: this.props.tup.key,
	    aspect: this.props.aspect,
	    label: lbl

	},(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
		    is_loading:false
		})
	    }
	})
    }
    
    handle_input_click(){
	console.log('input-click')
	let lbl =   this.props.parent_label + ': ' +this.props.label

	var d :{[k:string]:any}= {
	    acfkey: this.props.tup.key,
	    cmd: 'choice-vote',
	    trigger_recalc: true,
	    acf_key_step: this.props.tup.key,
	    acf_key_select: this.props.select.key,
	    //top3: Object.keys(this.state.values),
	    aspect: this.props.aspect,
	    label: lbl
	}

	this.props.cb_parent(d,(r:any)=>{
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		if(r.new_state){
		    this.setState({values:r.new_state, is_loading:false})
		}
	    }
	})
    }

    render(){
	//let tup = this.props.tup
	let has_edit_perm = false
	if(this.props.permission && this.props.permission.edit){
            has_edit_perm = this.props.permission.edit.current_user;
	}



	return (
	    <div className="collab-input-with-comment section-part">
		<div className="header">
		<div className="label">{this.props.label}</div>
		    <span data-balloon="Toon historie"
			  data-balloon-pos="up" className="cf-icon-btn"
			  onClick={this.handle_click_history}><i className="bb-icon-l bb-icon-list"/></span>

		</div>
		
		<div className="two-columns collab-values">
		{has_edit_perm ? (
		    <div className="values" onClick={this.handle_input_click}>
			{Object.entries(this.state.values).map(([attr_id, tup]: [string, any],i:number) => {
			    let win_mark = tup.winner ? '*':''
			    let nice = this.props.select.choices[tup.value]
			    return (
				<div key={i} className="collab-value">
				    <div className="value text"> {nice} {win_mark} </div>
				</div>
			    )
			})}
		    </div>
		) : (
		    <div className="values" >
			{Object.entries(this.state.values).map(([attr_id, tup]: [string, any],i:number) => {
			    let win_mark = tup.winner ? '*':''
			    let nice = this.props.select.choices[tup.value]
			    return (
				<div key={i} className="collab-value">
				    <div className="value readonly text"> {nice} {win_mark} </div>
				</div>
			    )
			})}
		    </div>
		)}
		    <div className="remarks" onClick={this.handle_comment_click}>
			<div className="text-with-btn">
			    <div className="text">
				{this.state.comments.map((txt:string,j:number)=> {
				    let k = `t-${j}`
				    return (<div key={k} className="part" >{txt}</div>)
				})}
			    </div>
			    <span className="cf-count-btn"
				  data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>

			</div>
		    </div>
		    
		</div>
		{has_edit_perm ? (

		<div className="buttons" onClick={this.handle_input_click}>
		   <span className="cf-button add-choice"> {Loco.tr('add_your_choice')}</span>
			</div>
		) : null }
	    </div>
	)
    }
}

export default CollabChoiceInput
