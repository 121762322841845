import * as React from 'react';



interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    handle_input_click: () => void,
    with_top3:boolean,
    has_edit_perm:boolean,
    pop_header:string,
    tup:any
}
type MyState = {
    value:string,
    comment_count:number,
    comments: Array<string>
}
class ValueWithComments extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    comments:[],
	    comment_count:0,
	    value:''
	}
    }
    componentDidMount(){
	if(this.props.tup.comments){
	    this.setState({
		comments: this.props.tup.comments.map((x:any)=>x.value),
		comment_count: this.props.tup.comment_count
	    })
	}
    }
    handle_comment_click(attr_id:string){

	console.log(`comments-clicked ${attr_id} (send to cb_parent)`)
	this.props.cb_parent({
	    cmd: 'inputs-comment-vote',
	    acfkey: this.props.tup.key,
	    attr_id: attr_id,
	    aspect:'huidig',
	    label: this.props.pop_header
	},(r:any)=>{
	    console.log('callback after comment-vote')
	    // fixme: find new_state 
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value)
		})
	    }
	})
	
    }
    render(){
	let tup = this.props.tup
	let t3_mark = this.props.with_top3 && this.props.tup.top3 ? '*' : ''
	let win_mark = this.props.tup.winner ? '*':''
	return (
	    <div  className="two-columns collab-value">
		{this.props.has_edit_perm ? (

		<div className="value"  onClick={this.props.handle_input_click}>
		<div className="text"> {tup.value} {t3_mark}{win_mark}</div>
		    <span data-balloon="Details / keuze"
			  data-balloon-pos="up"
			  className="cf-icon-btn"><i className="bb-icon-f bb-icon-ellipsis-h"/></span>
			</div>
		) : (
		    <div className="value readonly">
			<div className="text"> {tup.value} {t3_mark}{win_mark}</div>
			</div>
		)}
		<div className="remarks"  onClick={(e:any)=>this.handle_comment_click(tup.attr_id)} >
		    <div className="text-with-btn">
			<div className="text">
	    		    {this.state.comments.map((txt:string,j:number)=> 
				(<div className="part" key={j}>{txt}</div>)
			    )}
			    
			</div>
			<span className="cf-count-btn"
			      data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>

		    </div>
		</div>	
  	    </div>
	)
    }
}

export default ValueWithComments
