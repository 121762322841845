import * as React from 'react';
import WpApi from './wpapi'
import Loader from './Loader'
import Bouwdeel from './Bouwdeel'

interface MyProps {
    post_id:string,
    user:any,
    tup:any,
    permission:any,
    cb_parent: (e: any, cb: (r: any) => void) => void
}
type MyState = {
    rows:Array<any>,
    is_loading:boolean,
    show_select:boolean,
    bd_remaining:Array<any>
};

/*
 *  bd_remaining: filtered list of BouwDelen  ( building-parts) options, filtered for given section_name
 *    e.g. section_name 'isolatie_gevel'  ends with 'gevel' and filtered BD-options are:
 *      - gevel_bg
 *      - gevel_alle
 * 
 *  FIXME: for now, this only works for section 'isolatie'
 */

class Bouwdelen extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)
	this.render_select = this.render_select.bind(this)
	this.handle_select_change = this.handle_select_change.bind(this)
	this.render_row = this.render_row.bind(this)
	this.expand_rows = this.expand_rows.bind(this)
	this.state = {
	    rows:[],
	    is_loading:false,
	    show_select:false,
	    bd_remaining:[]
	}

    }
    componentDidMount(){
	this.expand_rows()
    }
    expand_rows(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}

	this.setState({is_loading:true})
	let path = '/bouwdelen'

	let d = {
	    post_id : this.props.post_id,
	    acf_field:this.props.tup.key
	}
	this.fetchPromise = WpApi.do_get(path,d, (ret) => {
	    let retlist : {[bd:string]:Array<any>} = ret.ret
	    console.log(retlist)

	    this.setState({is_loading:false, show_select:true})
	    //Object.entries(obj) as ObjectEntries<OBJ_T>;


	    Object.entries(retlist).forEach(([bd, tup]: [string, any])=>{
		var advises : Array<any> = []
		if(tup.advise !== undefined){
		    Object.entries(tup.advise).forEach(([aid,x]:[string,any])=>{
			let adv = x.value ? x.value : {}
			let coms = x.comments ? x.comments : []
			
			let tup = {
			    comments: coms,
			    advise: {
				value: adv,
				attr_id: x.attr_id
			    }
			}
			advises.push(tup)
		    })

		}
		let bdtup = {
		    slug: bd,
		    oordeel: tup.oordeel,
		    advises: advises,
		    tupadvise: tup.advise
		}
		this.setState(prevState => ({
		    rows: [...prevState.rows, bdtup]
		}))

	    })
	    // currently present BD-slugs:
	    let present_slugs = Object.keys(ret.ret)
	    console.log('present-slugs')
	    console.log(present_slugs)
	    var postfix = ""
	    if(this.props.tup.section_name.startsWith('isolatie')){
		let idx = 'isolatie_'.length
		postfix = this.props.tup.section_name.substr(idx)
		console.log(` postfix: ${postfix}`)
	    }

	    for(var [val,lab] of Object.entries(this.props.tup.opt_lists.bouwdelen)){
		let tup = {
		    val: val,
		    label: lab,
		}
		if(present_slugs.includes(val)){
		    continue
		}else{
		    if(postfix !== ""){
			if(val.startsWith(postfix)){
			    console.log('matches postfix')
			}else{
			    continue
			}
		    }
		    this.setState(prevState => ({
			bd_remaining: [...prevState.bd_remaining, tup]
		    }))
		}
	    }
	})
    }
    public handle_select_change(e:any){
	console.log('handle-sel-change')
	console.log(e)
	//this.setState({show_select:false})
	//let rows = this.state.rows
	let tup = {
	    slug: e.target.value,
	    oordeel: { comments:[]},
	    tupadvise:{},
	}
	console.log(tup)

	this.setState(prevState => ({
	    rows: [...prevState.rows, tup]
	}))

    }
    public render_select(){
	return (
	    <div className="add-bouwdeel"> <span> Voeg een bouwdeel toe: </span>
		<select style={{height:'unset'}} onChange={this.handle_select_change}>

		<option value="">-kies-</option>
		{this.state.bd_remaining.map((el,i)=><option key={i} value={el.val}>{el.label}</option>)}
 	    </select>
		</div>
	)
    }
    render_row(tup:any,i:number){
	let lab = this.props.tup.opt_lists.bouwdelen[tup.slug]
	return (
	    <Bouwdeel
		user={this.props.user}

	    advises={tup.tupadvise}
		key={i} name={tup.slug}
	    label={lab}
	    oordeel={tup.oordeel}

		cb_parent={this.props.cb_parent} tup={this.props.tup} />
	)
    }
    render(){
	console.log(this.props.tup)

	let label_txt = this.props.tup.label
	let label_txt2 = this.props.tup.label
	let slab = this.props.tup.section_label
	//let m = /^([A-Z0-9]+)[ .]+(.*)/.exec(slab)
	let m = /^([^{]+){parent}/.exec(this.props.tup.label)

	
	if(m){
	    console.log('rematch')
	    console.log(m)
	    let lab1 = m[1].trim()
	    label_txt = (<span>{lab1}: <i>{slab}</i></span>)
	    label_txt2 = (<span>{lab1} <i>{slab}</i></span>)
	}
	

	//let m = /^([A-Z0-9]+)[ .]+(.*)/.exec(slab)
	let perm_bp_edit = false
	if(this.props.permission){
	    perm_bp_edit = this.props.permission.buildingpart.edit.current_user
	}
	    

	return (
	    <div className="bouwdelen step">
		<div className="label">
			<span>{label_txt}</span>
		</div>

		{this.state.is_loading ? (<Loader/>) : null}
		<div className="rows"> {this.state.rows.map(this.render_row)}</div>
		
		{this.state.show_select && perm_bp_edit ? this.render_select() : null}
	    </div>
	)
    }
}
export default Bouwdelen
